import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import './config/axios'
import AxiosPlugin from 'vue-axios-cors';
import './config/icons'
import './config/msg'
import './config/moeda'
import Maska from 'maska'

Vue.config.productionTip = false

Vue.filter('dinheiro', valor => {
	return valor == null || isNaN(valor) ? 'R$ 0,00' : valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
})

Vue.filter('dinheiroDecimal', valor => {
	return valor == null || isNaN(valor) ? 'R$ 0,00' : valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL', minimumFractionDigits: 4});
})

const moment = require('moment')
require('moment/locale/pt-br')
Vue.use(require('vue-moment'), {
  moment
})

Vue.use(Maska)
Vue.use(AxiosPlugin)
new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')
