<template>
  <v-container fluid id="c-content" class="c-content c-content__principal ma-0 pa-0">
    <v-card v-if="usuarioLogado" dark color="primeira" class="ma-0 pa-0 c-content-pedidos">
      <tabelaDePedidos :dadosUser="dadosUser"></tabelaDePedidos>
    </v-card>
    <!--  <v-container>
      <v-row>
        <v-col>

        </v-col>
      </v-row>
    </v-container> -->

    <v-container v-else class="fill-height c-login">
      <v-fade-transition appear>
        <!--  <v-row class="d-flex flex-column justify-center align-center"> -->
        <v-row>
          <v-col cols="12">
            <login />
          </v-col>
        </v-row>
      </v-fade-transition>
    </v-container>
  </v-container>
</template>

<script>
import login from "../usuario/login";
/* import sellOut from "../sellout/sellOut"; */
import tabelaDePedidos from "../controleDePedidos/pedidos/tabelaDePedidos.vue";

export default {
  name: "Content-screen",
  components: { tabelaDePedidos, login },
  props: {
    usuarioLogado: Boolean,
    sessaoExpirada: Boolean,
    dadosUser: Object
  }
};
</script>
<style scoped>
.c-login {
  position: fixed;
  min-width: 100%;
}

.c-content__login {
  position: fixed;
  left: 0px;
  right: 0px;
}

.c-content-pedidos {
  height: 100%;
  min-height: 100%;
}

/* .c-content__principal{
  position: fixed;
    left: 0px;
    right: 0px;
} */
</style>