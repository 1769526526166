<template>
    <v-card dark>
        <v-card-title>
            <span class="text-h5 pl-3">{{ formTitle }}</span>
            <v-spacer></v-spacer>
            <v-btn small fab elevation="8" @click="close">
                <v-icon>close</v-icon>
            </v-btn>
        </v-card-title>

        <v-card-text>
            <!-- ################# ADD PEDIDOS ###################### -->
            <v-container fluid class="pt-5">
                <v-row>
                    <v-col cols="12" class="my-0 py-0">
                        <v-row>
                            <v-col cols="12" sm="6" md="3">
                                <v-select background-color="dprimeira" dense hide-details v-model="editedItem.empresa"
                                    :items="setEmpresa" label="Selecionar Empresa" outlined>
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                                <v-checkbox color="decima" dense v-model="editedItem.recebimentoMercadoria"
                                    :label="`Pedido Recebido?: ${editedItem.recebimentoMercadoria ? 'SIM' : 'NÃO'}`"></v-checkbox>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                        <v-autocomplete hide-details clearable id="c-selec-data-cliente" item-value="material"
                            item-text="razaoSocial" v-model="editedItem.orgao" :items="listaDeOrgaos"
                            :loading="buscandoOrgaos" outlined dense chips small-chips label="Selecionar Órgão"
                            background-color="dprimeira">
                            <template v-slot:item="{ item }">
                                <v-chip :color="item.color" label small class="u-cursor my-2">
                                    {{ item.razaoSocial }}
                                </v-chip>
                            </template>
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                        <v-autocomplete background-color="dprimeira" hide-details clearable id="c-selec-data-cliente"
                            item-value="material" item-text="material" v-model="editedItem.produto" :items="listaDeProdutos"
                            :loading="buscandoProdutos" outlined dense chips small-chips label="produto">
                            <template v-slot:item="{ item }">
                                <v-chip :color="item.color" label small class="u-cursor my-2">
                                    {{ item.material }}
                                </v-chip>
                            </template>
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="12">
                        <v-row justify="center">
                            <v-col cols="12" sm="6" md="2">
                                <v-text-field background-color="dprimeira" dense outlined hide-details hide-spin-buttons
                                    type="number" v-model.number="editedItem.quantidadePedida"
                                    label="Quant. Pedida"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="2">
                                <v-text-field background-color="dprimeira" dense outlined hide-details hide-spin-buttons
                                    type="number" v-model.number="editedItem.quantidadeCaixa"
                                    label="Quant. Caixa"></v-text-field>
                            </v-col>
                            <v-col cols=" 12" sm="6" md="2">
                                <v-currency-field background-color="dprimeira" hide-details prefix="R$" dense outlined
                                    v-model="editedItem.valorIndividual" label="Valor Individual">
                                </v-currency-field>
                            </v-col>
                            <v-col v-if="editedItem.recebimentoMercadoria" cols=" 12" sm="6" md="2">
                                <v-text-field type="number" color="decima" background-color="dprimeira" hide-details hide-spin-buttons
                                    dense outlined v-model.number="editedItem.quantidadeRecebida" label="Quant. Recebida">
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-row justify="center">
                            <v-col cols="12" sm="6" md="3">
                                <v-text-field dense outlined label="Quant. Total" readonly disabled
                                    :value="formattedTotalQuantity" suffix="Unidades" persistent-hint
                                    hint="Quant. da Caixa x Quant. Pedida"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="3">
                                <v-currency-field prefix="R$" dense outlined
                                    :value="(editedItem.quantidadeCaixa * editedItem.quantidadePedida) * editedItem.valorIndividual"
                                    label="Valor Total" disabled persistent-hint hint="Quant. Total x Valor Individual">
                                </v-currency-field>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- ################# IDENTIFICAÇÃO DO PEDIDO ###################### -->
                    <!-- SEPARADOR -->
                    <v-col cols="12">
                        <v-row no-gutters justify="center" align="center">
                            <v-divider unset />
                            <h5 class="mx-2">IDENTIFICAÇÃO DO PEDIDO</h5>
                            <v-divider unset />
                        </v-row>
                    </v-col>
                    <!-- FIM SEPARADOR -->
                    <v-col cols="12" sm="6" md="3">
                        <v-text-field dense outlined hide-details v-model="editedItem.pedidoNumero" label="N° do Pedido">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                        <v-text-field dense outlined hide-details v-model="editedItem.numeroEmpenho" label="N° Empenho">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                        <v-text-field dense outlined hide-details v-model="editedItem.pne" label="PNE"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                        <v-text-field dense outlined hide-details v-model="editedItem.editalPregao" label="Edital Pregão">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                        <v-text-field dense outlined hide-details v-model="editedItem.idSolution"
                            label="ID Solution"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                        <v-text-field dense outlined hide-details v-model="editedItem.nf" label="N° NF">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                        <v-text-field dense outlined hide-details v-model="editedItem.pedidoSigecloud" label="Sigecloud">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="2">
                        <v-text-field dense outlined hide-details v-model="editedItem.numeroSolutionOuPlataformaNi"
                            label="N°. Solution/Plataforma - Ni">
                        </v-text-field>
                    </v-col>
                    <!-- DATAS -->
                    <!-- SEPARADOR DATAS -->
                    <v-col cols="12" class="ma-0 pa-0">
                        <v-row no-gutters justify="center" align="center">
                            <v-divider unset />
                            <h5 class="mx-2">DATAS</h5>
                            <v-divider unset />
                        </v-row>
                    </v-col>
                    <!-- FIM SEPARADOR DATAS -->
                    <v-col cols="12" class="pt-8">
                        <v-row justify="center">
                            <v-col cols="12" sm="6" md="3">
                                <v-text-field background-color="dprimeira" dense type="datetime-local"
                                    v-model="editedItem.dataDoPedido" label="Data do Pedido"
                                    hint="Data que foi solicitado o pedido" persistent-hint>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="3">
                                <v-text-field background-color="dprimeira" v-if="editedItem.recebimentoMercadoria" dense
                                    type="datetime-local" v-model="editedItem.dataDoRecebimento" label="Data do Recebimento"
                                    hint="Data do recebimento do pedido" persistent-hint>
                                </v-text-field>
                                <v-text-field v-else dense type="datetime-local" v-model="editedItem.previsaoDeRecebimento"
                                    label="Previsão de Recebimento" hint="Previsão para o recebimento do pedido"
                                    persistent-hint>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="3">
                                <v-text-field dense type="datetime-local" v-model="editedItem.prazoDeFaturamento"
                                    label="Prazo de Faturamento" hint="Prazo para o faturamento do pedido" persistent-hint>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="3">
                                <v-text-field dense type="datetime-local" v-model="editedItem.inclusaoDePedidoNestle"
                                    label="Inclusão de Pedido Nestlé" hint="Dia da inclusão do pedido na Nestlé"
                                    persistent-hint>
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- OBSERVAÇÕES -->
                    <v-col cols="12">
                        <v-textarea dense outlined hide-details v-model="editedItem.obs" label="Observaçãos">
                        </v-textarea>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>

        <v-card-actions dark color="primeira" class="pb-8">
            <v-spacer></v-spacer>
            <v-btn color="darken-1" @click="close" class="mr-2">CANCELAR CADASTRO</v-btn>
            <v-btn color="decima" @click="save">CADASTRAR NOVO PEDIDO</v-btn>
        </v-card-actions>
    </v-card>
</template>
  
<script>
import axios from "axios";
import { urlApi, showError, cofingK } from "@/global";
export default {
    name: "AddPedidoOuEditarPedidos",
    props: {
        dataItem: Object,
        dataDefaultItem: Object,
        dialogAddOuEditProduto: Boolean,
        formTitle: String
    },
    components: {},
    data: () => ({
        buscandoOrgaos: false,
        buscandoProdutos: false,
        setEmpresa: ['NUTRIMIX', 'NUTRIPLAN'],
        editedItem: {},
        defaultItem: {},
        listaDeProdutos: [],
        listaDeOrgaos: [],
    }),
    computed: {
        formattedTotalQuantity() {
            const totalQuantity = this.editedItem.quantidadeCaixa * this.editedItem.quantidadePedida;
            return totalQuantity.toLocaleString('pt-BR');
        },
    },
    watch: {
        dialogAddOuEditProduto: {
            immediate: true,
            handler(val) {
                if (val) {
                    this.editedItem = Object.assign({}, this.dataItem);
                    this.listarProdutos();
                    this.listarOrgaos();
                    const dataFormatada1 = this.$moment(this.dataItem.dataDoPedido).format("YYYY-MM-DDTHH:mm");
                    const dataFormatada2 = this.$moment(this.dataItem.dataDoRecebimento).format("YYYY-MM-DDTHH:mm");
                    const dataFormatada3 = this.$moment(this.dataItem.prazoDeFaturamento).format("YYYY-MM-DDTHH:mm");
                    const dataFormatada4 = this.$moment(this.dataItem.previsaoDeRecebimento).format("YYYY-MM-DDTHH:mm");
                    const dataFormatada5 = this.$moment(this.dataItem.inclusaoDePedidoNestle).format("YYYY-MM-DDTHH:mm");
                    this.editedItem.dataDoPedido = dataFormatada1;
                    this.editedItem.dataDoRecebimento = dataFormatada2;
                    this.editedItem.prazoDeFaturamento = dataFormatada3;
                    this.editedItem.previsaoDeRecebimento = dataFormatada4;
                    this.editedItem.inclusaoDePedidoNestle = dataFormatada5;
                } else {
                    this.editedItem = Object.assign({}, this.dataDefaultItem);
                }
            },
        }
    },
    methods: {
        listarOrgaos() {
            this.custoLoading = true;
            axios
                .post(`${urlApi}/listar-clientes`, this.user)
                .then((res) => {
                    this.listaDeOrgaos = res.data.result;
                    this.buscandoOrgaos = false;
                })
                .catch((err) => {
                    this.buscandoOrgaos = false;
                    showError(`Erro ao buscar orgaos | ${err}`);
                });
        },
        listarProdutos() {
            axios
                .post(`${urlApi}/listar-produtos`, this.user, { headers: cofingK })
                .then((res) => {
                    this.listaDeProdutos = res.data.result;
                    this.buscandoProdutos = false;
                })
                .catch((err) => {
                    this.buscandoProdutos = false;
                    showError(`Erro ao buscar produtos | ${err}`);
                });
        },
        close() {
            this.$emit("close");
        },
        save() {
            this.editedItem.quantidadeTotal = this.editedItem.quantidadeCaixa * this.editedItem.quantidadePedida;
            this.editedItem.valorTotal = this.editedItem.quantidadeTotal * this.editedItem.valorIndividual;
            if (!this.editedItem.recebimentoMercadoria) {
                this.editedItem.quantidadeRecebida = 0;
                this.editedItem.dataDoRecebimento = null;
            }
            this.$emit("save", this.editedItem);
        }
    },
    created() { },
    beforeMount() { },
    mounted() { }
};
</script>
  
<style></style>