<template>
  <v-app class="s-app">
    <Header v-if="usuarioLogado" :dadosUser="dadosUser" />
    <Loader v-if="!validatingToken" />
    <Content v-else :usuarioLogado="usuarioLogado" :sessaoExpirada="sessaoExpirada" :dadosUser="dadosUser" class="ma-0 pa-0" />
  </v-app>
</template>

<script>
import axios from "axios";
import { urlApi, showError, userKey, apiMagically } from "@/global";
import barramento from "@/barramento";
import Header from "./components/tm/Header";
import Content from "./components/tm/Content";
import Loader from "./components/tm/Loader";

export default {
  name: "App",
  components: { Header, Content, Loader },
  data: function () {
    return {
      validatingToken: true,
      usuarioLogado: false,
      sessaoExpirada: false,
      dadosUser: {},
    };
  },
  methods: {
    getUrlApiMagical(email) {
      axios
        .post(`${urlApi}/get-dados-sistema`, {})
        .then((res) => {
          apiMagically.urlsApi = res.data.result[0].urlsApi
          apiMagically.logEmailUser = email
        })
        .catch(showError);
    },
    loginNovamente() {
      const json = localStorage.getItem(userKey);
      const userData = JSON.parse(json);

      if (!userData) {
        this.usuarioLogado = false;
        this.dadosUser = {};
        return;
      } else {
        axios.post(`${urlApi}/validar-token`, {}, {
          headers: {
            "X-Parse-Session-Token": userData.sessionToken
          }
        }).then(res => {
          localStorage.setItem(userKey, JSON.stringify(res.data.result));
          axios.defaults.headers.post['X-Parse-Session-Token'] = res.data.result.sessionToken
          this.dadosUser = res.data.result;
          this.getUrlApiMagical(res.data.result.email)
          this.usuarioLogado = true;
        }).catch(showError);
      }
    },
  },
  created() {
    this.loginNovamente();
    barramento.getStatusLogin((isTrue, sessaoExpirada) => {
      if (isTrue) {
        const userAltenticado = localStorage.getItem(userKey);
        this.dadosUser = Object.assign({}, JSON.parse(userAltenticado))
        this.usuarioLogado = true;
      }
      if (sessaoExpirada) {
        this.dadosUser = {}
        this.usuarioLogado = false;
      }
    });
  },
};
</script>

<style>
* {
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.5px;
  font-weight: 500;
  box-sizing: border-box;
}
.u-position-relative{
  position: relative;
}
.u-cursor{
  cursor: pointer;
}

.text-h5 {
  word-break: break-word;
}
.u-opacidade{
  opacity: 0.8;
}

.u_largura-90 {
  width: 135px;
}

.u-largura-135 {
  width: 135px;
}

.u-width-fixa__115 {
  width: 115px;
}

.u-width-fixa__220 {
  width: 220px;
}

.u-width-fixa__320 {
  width: 320px;
}

.u-width-fixa__520 {
  width: 520px;
}

.u-absolute {
  position: absolute;
}

.s-app {
  background: transparent !important;
}

body {
  margin: 0px;
  background: #212121;
}

/*ScrolBar*/
::-webkit-scrollbar {
  width: 1px;
  height: 7px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  border: none;
  background: rgb(54 52 148 / 38%);
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background: #363494;
}

::-webkit-scrollbar-thumb:active {
  background: #363494;
}

::-webkit-scrollbar-track {
  background: none;
  border: none;
}

::-webkit-scrollbar-track:hover {
  background: #dddddd;
}

::-webkit-scrollbar-track:active {
  background: #dddddd;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

.u-hide {
  display: none !important;
}

.bg-teste {
  background: chartreuse;
}

.bg-teste2 {
  background: rgb(255, 174, 0);
}

.bg-teste3 {
  background: rgb(4, 116, 145);
}

.bg-teste4 {
  background: rgb(0, 95, 24);
}

.alerta.error {
  background: #ff3131 !important;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  min-height: 100%;
}

.c-header__topo {
  width: 100vw !important;
  height: 63px;
}

.c-header__col {
  height: 63px;
}

.c-col__fase__gestor {
  min-width: 280px;
  max-width: 280px;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
}

.c-col__fase__gestor::-webkit-scrollbar {
  width: 2px;
}

.c-col__fase__gestor::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

.c-col__fase__gestor::-webkit-scrollbar-corner {
  background: none;
}

.c-col__fase__gestor::-webkit-scrollbar-thumb {
  border: 4px solid #f0f8ff;
  width: 20px !important;
  height: 20px !important;
}

.c-col__fase__gestor:hover::-webkit-scrollbar-thumb {
  border: 4px solid #363494;
  width: 20px !important;
  height: 20px !important;
}

.c-col__fase__gestor::-webkit-scrollbar-track {
  background: #f0f8ff;
  border: none;
}

.u-relative {
  position: relative;
}</style>