<template>
    <v-scroll-y-transition v-if="isVisualizar">
        <v-row :key="pedido.objectId" dense class="resumo-pedido mt-2 grey darken-4 pa-5 pt-3 rounded-lg elevation-5">
            <v-col cols="12" class="ma-0 pa-0 u-position-relative">
                <v-btn @click="cop(pedido.objectId)" elevation="10" x-small color="decima"
                    class="destaquePlus--text text-overline mt-n2">{{ pedido.objectId
                    }} <v-icon right color="">content_copy</v-icon> </v-btn> <br />
                <!-- #####BTN DE EDITAR PEDIDO###### -->
                <v-btn absolute right top icon @click="editarPedido(pedido)" elevation="10" color="quinta"
                    class="mt-n5 mr-n6"><v-icon color="decima">app_registration</v-icon> </v-btn>
                <v-badge inline color="transparent"
                    :class="statusPedido(pedido.recebimentoMercadoria, pedido.quantidadeDisponivel)">
                    <template v-slot:badge>
                        <span class="badge-dot"></span>
                    </template>
                    <h5 class="mr-2">{{ pedido.orgao }}</h5>
                    <v-chip v-if="pedido.recebimentoMercadoria" dark x-small label outlined color="decima">PEDIDO
                        RECEBIDO</v-chip>
                    <v-chip v-else dark x-small label color="red">PEDIDO NÃO RECEBIDO</v-chip>
                </v-badge>
                <v-row v-if="'dataInsights' in pedido">
                    <v-col cols="12" md="9" class="mb-0 pb-0">
                        <h2 class="white--text">{{ pedido.produto }}</h2>
                    </v-col>
                    <v-col cols="12" class="ma-0 pa-0 mt-n2">
                        <!-- ##### BTN DE INFORMAÇÕES SOBRE O PEDIDO -->
                        <v-row class="mx-0 pa-0 mt-2 mb-3" align-content="center" justify="center" dense>
                            <v-col cols="12" md="2" class="text-center">
                                <v-btn height="25" block color="decima" class="destaquePlus--text elevation-24">{{
                                    pedido.quantidadeRecebida }}</v-btn>
                                <p class="font-weight-black u-fonte pa-0 ma-0 pt-1 white--text">QUANT. RECEBIDA
                                </p>
                            </v-col>
                            <v-col cols="12" md="2" class="text-center">
                                <v-btn height="25" block color="decima" class="destaquePlus--text elevation-24">{{
                                    pedido.quantidadeDisponivel }}</v-btn>
                                <p class="font-weight-black u-fonte pa-0 ma-0 pt-1 white--text">DISPONÍVEL PARA
                                    EMPRÉSTIMO
                                </p>
                            </v-col>
                            <v-col cols="12" md="2" class="text-center">
                                <v-btn height="25" block color="decima" class="destaquePlus--text elevation-24">{{
                                    pedido.dataInsights.somaDosEmprestimos }}</v-btn>
                                <p class="font-weight-black u-fonte pa-0 ma-0 pt-1 white--text">TOTAL EMPRESTADO
                                </p>
                            </v-col>
                            <v-col cols="12" md="2" class="text-center">
                                <v-btn height="25" block color="decima" class="destaquePlus--text elevation-24">{{
                                    pedido.dataInsights.totalResgatadoDosEmp }}</v-btn>
                                <p class="font-weight-black u-fonte pa-0 ma-0 pt-1 white--text">TOTAL RESGATADO
                                </p>
                            </v-col>
                            <v-col cols="12" md="2" class="text-center">
                                <v-btn height="25" block color="decima" class="destaquePlus--text elevation-24">{{
                                    pedido.dataInsights.somaDasDevolucoes }}</v-btn>
                                <p class="font-weight-black u-fonte pa-0 ma-0 pt-1 white--text">TOTAL DEVOLVIDO
                                </p>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider class="my-1" />
                <span class="text-caption font-weight-black destaquePlus--text font-weight-black">QUANT. RECEBIDA:
                    {{
                        pedido.quantidadeRecebida }}</span>
                <span class="text-caption decima--text"> | QUANT. PED: <span class="white--text">{{
                    pedido.quantidadePedida
                }}</span></span>
                <v-tooltip v-if="pedido.quantidadePedida - pedido.quantidadeRecebida > 0 && pedido.recebimentoMercadoria"
                    bottom color="red">
                    <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on" class="text-caption font-weight-black red--text u-cursor"> |
                            QUANT. RESTANTE:
                            {{
                                pedido.quantidadePedida - pedido.quantidadeRecebida }}<v-icon x-small color="red"
                                class="mt-n1">announcement</v-icon> </span>
                    </template>
                    <span>A EMPRESA NÃO RECEBEU TODAS AS UNIDADES DO PEDIDO!</span>
                </v-tooltip>
                <span class="text-caption decima--text"> | QUANT. CAIXA: <span class="white--text">{{
                    pedido.quantidadeCaixa
                }}</span></span>
                <span class="text-caption decima--text"> | QUANT. TOTAL: <span class="white--text">{{
                    pedido.quantidadeTotal
                }} Uni.</span></span>
                <span class="text-caption decima--text"> | VALOR IND: <span class="white--text">{{
                    pedido.valorIndividual |
                    dinheiro }}</span></span><br />
                <span class="text-caption decima--text">VALOR TOTAL: <span class="white--text"> {{ pedido.valorTotal
                    |
                    dinheiro }} </span> </span>

                <!-- ########## DATAS ########## -->
                <v-col cols="12 ma-0 pa-0">
                    <v-row no-gutters justify="center" align="center" class="ma-0 pa-0">
                        <v-divider unset />
                        <h5 class="mx-2">DATAS</h5>
                        <v-divider unset />
                    </v-row>
                </v-col>
                <span class="text-caption decima--text">DATA DO PEDIDO:
                    <span class="white--text">
                        {{ pedido.dataDoPedido | moment("DD/MM/YYYY [ÀS] HH:mm:ss") }}
                    </span>
                </span>
                <span v-if="pedido.recebimentoMercadoria" class="text-caption decima--text"> | DATA DO RECEBIMENTO:
                    <span class="white--text">
                        {{ pedido.dataDoRecebimento | moment("DD/MM/YYYY [ÀS] HH:mm:ss") }}
                    </span>
                </span>
                <span v-if="!pedido.recebimentoMercadoria && pedido.previsaoDeRecebimento !== null"
                    class="text-caption decima--text">
                    | PREVISÃO DE RECEBIMENTO:
                    <span class="white--text">
                        {{ pedido.previsaoDeRecebimento | moment("DD/MM/YYYY [ÀS] HH: mm: ss") }}
                    </span>
                </span>
                <span v-if="pedido.prazoDeFaturamento !== null" class="text-caption decima--text"> | PRAZO DE
                    FATURAMENTO:
                    <span class="white--text">
                        {{ pedido.prazoDeFaturamento | moment("DD/MM/YYYY [ÀS] HH:mm:ss") }}
                    </span>
                </span>
                <span v-if="pedido.inclusaoDePedidoNestle !== null" class="text-caption decima--text"> | INCLUSÃO DE
                    PEDIDO
                    NESTLÉ:
                    <span class="white--text">
                        {{ pedido.inclusaoDePedidoNestle | moment("DD/MM/YYYY [ÀS] HH:mm:ss") }}
                    </span>
                </span>

                <!-- ########## IDENTIFICADORES ########## -->
                <v-col cols="12 ma-0 px-0 py-1">
                    <v-row no-gutters justify="center" align="center" class="ma-0 pa-0">
                        <v-divider unset />
                        <h5 class="mx-2">IDENTIFICADORES</h5>
                        <v-divider unset />
                    </v-row>
                </v-col>

                <span class="text-caption decima--text">EMPRESA: <span class="white--text">{{ pedido.empresa
                }}</span></span>
                <span class="text-caption decima--text"> | N°. PEDIDO: <span class="white--text">{{
                    pedido.pedidoNumero
                }}</span></span>
                <span class="text-caption decima--text"> | N°. EMPENHO: <span class="white--text">{{
                    pedido.numeroEmpenho
                }}</span></span>
                <span class="text-caption decima--text"> | PNE: <span class="white--text">{{ pedido.pne
                }}</span></span>
                <span class="text-caption decima--text"> | EDITAL PREGÃO: <span class="white--text">{{
                    pedido.editalPregao
                }}</span></span>
                <span class="text-caption decima--text"> | ID SOLUTION: <span class="white--text">{{
                    pedido.idSolution
                }}</span></span>
                <span class="text-caption decima--text"> | NF: <span class="white--text">{{ pedido.nf
                }}</span></span>
                <span class="text-caption decima--text"> | SIGECLOUD: <span class="white--text">{{
                    pedido.pedidoigecloud
                }}</span></span>
                <span class="text-caption decima--text"> | N° SOLUTION/PLATAFORMA-NI: <span class="white--text">{{
                    pedido.idSolution }}</span></span>
                <v-textarea dense hide-details :value="pedido.obs" label="Observaçãos" disabled row-height="5" auto-grow
                    class="mt-5"></v-textarea>
            </v-col>
        </v-row>
    </v-scroll-y-transition>
</template>
  
<script>
import { showError } from "@/global";
export default {
    name: "resumoPedido",
    props: {
        pedido: Object,
        isVisualizar: Boolean
    },
    computed: {
        statusPedido() {
            return (status, valor) => {
                if (status && valor === 0) {
                    return 'pulsing-verde-statico';
                }
                if (status && valor !== 0) {
                    return 'pulsing-verde';
                }
                if (!status) {
                    return 'pulsing-red';
                }
            }
        }
    },
    watch: {
    },
    methods: {
        editarPedido() {
            this.$emit("editarPedidoSelecionado", this.pedido);
        },
        cop(id) {
            navigator.clipboard.writeText(id)
                .then(() => {
                    // Feedback de sucesso (opcional)
                    this.$toasted.global.defaultSuccess({
                        msg: 'Copiado para a área de transferência!'
                    });
                })
                .catch(err => {
                    // Tratamento de erros (opcional)
                    showError('Falha ao copiar:', err);
                });
        }
    },
};
</script>

<style scoped>
.resumo-pedido .u-fonte {
    font-size: 8px;
    line-height: 10px;
}

.resumo-pedido .u-absolute {
    position: absolute;
    top: 0;
    left: 0;
}

.pulsing-red .badge-dot {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: red;
    border-radius: 50%;
    position: relative;
    animation: pulse 1.5s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(0.9);
        box-shadow: 0 0 0 0 rgba(196, 0, 0, 0.5);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 25px rgba(255, 60, 0, 0);
    }

    100% {
        transform: scale(0.9);
        box-shadow: 0 0 0 0 rgba(90, 153, 212, 0);
    }
}

/* Pulsing Badge Verde */

.pulsing-verde .badge-dot {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: #0baf2e;
    /* Alterado para verde */
    border-radius: 50%;
    position: relative;
    animation: pulseVerde 1.5s infinite;
}

@keyframes pulseVerde {
    0% {
        transform: scale(0.9);
        box-shadow: 0 0 0 0 rgba(11, 175, 46, 0.5);
        /* Alterado para verde */
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 25px rgba(11, 175, 46, 0);
        /* Alterado para verde */
    }

    100% {
        transform: scale(0.9);
        box-shadow: 0 0 0 0 rgba(11, 175, 46, 0);
        /* Alterado para verde */
    }
}

.pulsing-verde-statico .badge-dot {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: #0baf2e;
    /* Alterado para verde */
    border-radius: 50%;
    position: relative;
}
</style>
  