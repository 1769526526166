<!-- eslint-disable -->
<template>
  <v-row class="ma-0 pa-0 c-system-2-row">
    <!-- ############# TABELA DE PEDIDOS ############ -->

    <v-col cols="12" md="6" class="c-system-1-coluna ma-0 pa-0">
      <v-data-table height="100%" @item-selected="identificarSelecao" fixed-header calculate-widths
        selectable-key="objectId" sort-by="createdAt" sort-desc v-model="produtosSelecionados" show-select
        :single-select="selecaoUnica" :items="produtos" :headers="cabecalhoTabela" item-key="objectId" :search="search"
        class="grey darken-4 elevation-1 pt-5 c-tabela-produtos rounded-0" :loading="custoLoading" :loader-height="0"
        id="c-tabela-pedidos">

        <!-- Configurações para o topo da tabela -->
        <template v-slot:top color="grey darken-4">
          <!-- <v-progress-linear absolute top :height="8" :active="custoLoading" :indeterminate="custoLoading"
                color="#727272" class="pt-1">
              </v-progress-linear> -->
          <v-toolbar :rounded="false" flat color="grey darken-4" class="mb-5">
            <v-text-field outlined class="mx-3" v-model="search" append-icon="mdi-magnify" label="Buscar Pedido"
              single-line hide-details></v-text-field>
            <v-btn disabled class="mx-5" fab color="primeira" dark>
              <v-icon large>article</v-icon>
            </v-btn>
            <!-- <v-divider class="mx-1" inset vertical></v-divider>
              <v-spacer></v-spacer> -->

            <!--  Dialogo para editar ou add pedido -->
            <v-dialog v-model="dialogAddOuEditProduto" fullscreen>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primeira" fab v-bind="attrs" v-on="on">
                  <v-icon large>add</v-icon>
                </v-btn>
              </template>

              <!-- #### Componente para adicionar ou editar pedidos #### -->
              <addPedidoOuEditar :dataItem="editedItem" :dataDefaultItem="defaultItem"
                :dialogAddOuEditProduto="dialogAddOuEditProduto" :formTitle="formTitle" @close="close" @save="save">
              </addPedidoOuEditar>

            </v-dialog>

            <!-- Dialogo para deletar produto -->
            <v-dialog dark v-model="dialogDelete" max-width="500px">
              <v-card color="primeira">
                <v-card-title class="text-h5">Tem certeza de que deseja excluir este produto?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="darken-1" text @click="closeDelete">Cancel</v-btn>
                  <v-btn color="darken-1" text @click="deleteItemConfirm">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>



        <!-- Dados do cabeçalho -->
        <template v-slot:header.actions="props">
          {{ (props.header.cellClass = isEditarProduto ? "" : "u-hide") }}
          {{ (props.header.class = isEditarProduto ? "" : "u-hide") }}
          {{ props.header.text }}
        </template>

        <template v-slot:header.data-table-select="props">
          {{ props.header.cellClass = "u-hide" }}
          {{ props.header.class = "u-hide" }}
        </template>

        <!--################## Dados da tabela #####################-->

        <template v-slot:item="{ item, index, isSelected, select }">
          <tr :class="isPar(index, isSelected)" class="u-cursor" @click="select($event)" :key="index">
            <!-- DADOS DA EMPRESA -->
            <td>{{ item.empresa }}
              <v-btn @click="cop(item.objectId)" x-small color="transparent"
                class="destaquePlus--text mb-2 py-3 px-0u-text-id">{{ item.objectId
                }} <v-icon right color="">content_copy</v-icon>
              </v-btn>
              <v-btn v-if="isHabilitarEdicao" elevation="10" x-small fab color="" @click="removerPedido(item.objectId)">
                <v-icon small>mdi-delete</v-icon>
              </v-btn>

            </td>
            <!-- DADOS DO PEDIDO -->
            <td>
              <div>
                <p class="mb-1">
                  {{ item.orgao }}
                </p>
                <span>
                  PNE: {{ item.pne }}
                </span>
                <br />
                <span>
                  Empenho:<br />{{ item.numeroEmpenho }}
                </span>
              </div>
            </td>
            <!-- INFO. DO PRODUTO -->
            <td>
              <div>
                <p class="mb-1">
                  {{ item.produto }}
                </p>
                <span>
                  Quant. Ped: {{ item.quantidadePedida }}
                </span>
                <br />
                <span>
                  Quant. Caixa: {{ item.quantidadeCaixa }}
                </span>
                <span>
                  Quant. Receb: {{ item.quantidadeRecebida }}
                </span>
              </div>
            </td>
            <!-- DATA DO PEDIDO -->
            <td>
              <v-badge inline :color="item.recebimentoMercadoria ? 'green' : 'red'">
                <div class="d-flex flex-column align-center">

                  <span class="d-flex flex-column align-center justify-center mb-4">
                    <span class="u-font-x-samll">DATA DO PEDIDO</span>
                    <v-chip small class="elevation-3 mt-1">
                      {{ item.dataDoPedido | moment("DD/MM/YYYY") }}
                    </v-chip>
                  </span>
                  <!-- PREVISÃO DO RECEBIMENTO -->
                  <span v-if="!item.recebimentoMercadoria && item.previsaoDeRecebimento !== null"
                    class="d-flex flex-column align-center justify-center">
                    <span class="u-font-x-samll">PREVISÃO</span>
                    <v-chip small class="elevation-3 mt-1">
                      {{ item.previsaoDeRecebimento | moment("DD/MM/YYYY") }}
                    </v-chip>
                  </span>
                  <!-- DATA DO RECEBIMENTO -->
                  <span v-if="item.recebimentoMercadoria && item.dataDoRecebimento !== null"
                    class="d-flex flex-column align-center justify-center">
                    <span class="u-font-x-samll">RECEBIMENTO</span>
                    <v-chip small class="elevation-3 mt-1">
                      {{ item.dataDoRecebimento | moment("DD/MM/YYYY") }}
                    </v-chip>
                  </span>
                </div>
              </v-badge>
            </td>
          </tr>
        </template>
        <!--   Botões para Editar Produtos -->
        <template v-if="isEditarProduto" v-slot:item.actions="{ item }">
          <v-row v-if="!item.naoSelecionado">
            <v-col>

            </v-col>
          </v-row>
        </template>
        <!--     Botão para carregar produtos -->
        <template v-slot:no-data>
          <v-btn color="primeira" @click="initialize">Carregar dados</v-btn>
        </template>
      </v-data-table>
    </v-col>
    <!-- ######## INICIO SEGUNDA COLUNA ############# -->
    <v-col cols="12" md="6" class="ma-0 pa-3 pt-1 pb-8 dprimeira c-system-2-coluna rounded-0">
      <resumoPedido :pedido="produtosSelecionados[0]" :isVisualizar="produtosSelecionados.length > 0"
        @editarPedidoSelecionado="editarPedido($event)"></resumoPedido>
      <v-col cols="12" class="my-0 py-0">
        <v-row v-if="produtosSelecionados.length > 0"
          class="resumo-pedido grey darken-4 py-5 rounded-lg elevation-5 mt-4">
          <!-- ####### BOTÕES PARA EMPRESTAR E PEDIR ########### -->
          <v-col cols="12" md="4" class="my-0 py-0">
            <v-btn :disabled="produtosSelecionados[0].quantidadeDisponivel === 0" large @click="fazerEmprestimo(true)"
              color="primeira" class="elevation-24 white--text elevation-24">
              EMPRESTAR PARA<br /> OUTRO PEDIDO
              <v-icon right color="">mdi-truck-delivery-outline</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" md="4" class="my-0 py-0">
            <v-btn disabled large @click="fazerEmprestimo(false)" color="primeira"
              class="elevation-24 white--text elevation-24">
              EMPRESTAR PARA <br />OUTRO ORGÃO
              <v-icon right color="">mdi-truck-delivery-outline</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" md="3" class="my-0 py-0">
            <v-btn disabled large color="quarta" class="elevation-24 white--text elevation-24">
              PEDIR
              <v-icon right color="" style="transform: scaleX(-1);">mdi-truck-delivery-outline</v-icon>
            </v-btn>
          </v-col>
          <!--  FIM DOS BOTOES PARA EMPRESTAR -->
          <!-- ######## Ação emprestar ####### -->
          <v-col v-if="isGerarEmprestimo" cols="12">
            <v-row class="pa-3">
              <!-- ######## AUTO COMPLETE PARA SELECIONAR OUTRO PEDIDO ########-->
              <v-col v-if="isParaOutroPedido" cols="12" class="ma-0 pa-0">
                <v-row class="ma-0 pa-0" dense>
                  <v-col cols="12">
                    <v-autocomplete return-object hide-details id="c-selec-data-cliente" item-value="objectId" item-text="orgao" v-model="pedidoParaGerarEmprestimo" @change="verificarItemSelecionado()" :items="apenasPedidosDoProdutoSelecionado(produtos)" :loading="buscandoDataCliente" outlined dense chips small-chips label="Empréstimo para outro Pedido"
                    :filter="filtroParaBuscarPedido"
                    >
                      <template v-slot:item="{ item }">
                        <v-sheet rounded="lg" color="primeira" elevation="1" height="auto" width="100%"
                          class="my-2 pa-3 u-cursor">
                          <h5 class="mb-1 white--text">PEDIDO: <v-btn @click="cop(item.objectId)" elevation="10" x-small
                              color="transparent" class="white--text text-overline">{{ item.objectId
                              }} <v-icon right color="">content_copy</v-icon></v-btn> </h5>
                          <v-chip small class="u-cursor">
                            <strong class="primary--text">ÓRGÃO: </strong> &nbsp;
                            <strong class="quarta--text"> {{ item.orgao }} | </strong> &nbsp;
                            <strong class="primary--text"> PRODUTO: </strong> &nbsp;
                            <strong class="quarta--text"> {{ item.produto }} </strong>
                          </v-chip> <br />
                          <v-chip small class="u-cursor mt-1">
                            <span class="primary--text">Data do Pedido:</span> &nbsp;
                            <strong class="quarta--text"> {{ item.dataDoPedido | moment('DD/MM/YYYY HH:mm:ss')
                            }}</strong>
                            &nbsp;
                            <span class="primary--text">PNE:</span> &nbsp;
                            <strong class="quarta--text"> {{ item.pne }}</strong> &nbsp;
                            <span class="primary--text">ID Solution:</span> &nbsp;
                            <strong class="quarta--text">{{ item.idSolution }}</strong> &nbsp;
                            <span class="primary--text">N° Empenho:</span> &nbsp;
                            <strong class="quarta--text">{{ item.numeroEmpenho }}</strong>
                          </v-chip><br />
                          <v-chip small class="u-cursor mt-1" color="monosexta">
                            <span class="primary--text">N° do Pedido:</span> &nbsp;
                            <strong class="quarta--text"> {{ item.pedidoNumero }}</strong>
                          </v-chip>
                        </v-sheet>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col v-if="Object.keys(pedidoParaGerarEmprestimo).length != 0" cols="12">
                    <v-sheet rounded="lg" color="sexta" elevation="10" height="auto" width="100%"
                      class="my-2 pa-3 u-cursor">
                      <h5 class="mb-1 quarta--text font-weight-black">EMPRÉSTAR PARA O PEDIDO:</h5>
                      <v-btn @click="cop(pedidoParaGerarEmprestimo.objectId)" elevation="10" x-small color="transparent"
                        class="quarta--text text-overline">{{ pedidoParaGerarEmprestimo.objectId
                        }} <v-icon right color="">content_copy</v-icon></v-btn><br />
                      <!-- Orgão -->
                      <v-chip label small class="u-cursor my-2" color="monosexta">
                        <strong class="primary--text">Órgão: </strong> &nbsp;
                        <strong class="quarta--text font-weight-black"> {{ pedidoParaGerarEmprestimo.orgao }}</strong>
                      </v-chip> <br />
                      <!-- Produto -->
                      <v-chip label small color="monosexta">
                        <strong class="primary--text"> Produto: </strong> &nbsp;
                        <strong class="quarta--text font-weight-black"> {{ pedidoParaGerarEmprestimo.produto }}
                        </strong>
                      </v-chip>
                      <v-chip label small class="u-cursor my-1" color="monosexta">
                        <strong class="primary--text">Data do Pedido:</strong> &nbsp;
                        <strong class="quarta--text font-weight-black"> {{ pedidoParaGerarEmprestimo.dataDoPedido |
                          moment('DD/MM/YYYY HH:mm:ss')
                        }}</strong>
                        &nbsp;
                        <strong class="primary--text">PNE:</strong> &nbsp;
                        <strong class="quarta--text font-weight-black"> {{ pedidoParaGerarEmprestimo.pne }}</strong>
                        &nbsp;
                        <strong class="primary--text">ID Solution:</strong> &nbsp;
                        <strong class="quarta--text font-weight-black">{{ pedidoParaGerarEmprestimo.idSolution
                        }}</strong>
                        &nbsp;
                      </v-chip>
                      <v-chip label small color="monosexta">
                        <strong class="primary--text">N° Empenho:</strong> &nbsp;
                        <strong class="quarta--text font-weight-black">{{ pedidoParaGerarEmprestimo.numeroEmpenho
                        }}</strong>
                      </v-chip><br />
                      <v-chip small class="u-cursor mt-1" color="monosexta">
                        <span class="primary--text">N° do Pedido:</span> &nbsp;
                        <strong class="quarta--text"> {{ pedidoParaGerarEmprestimo.pedidoNumero }}</strong>
                      </v-chip>
                    </v-sheet>
                  </v-col>
                </v-row>
              </v-col>
              <!-- AUTO COMPLETE PARA SELECIONAR O ORGÃO PARA GERAR EMPRESTIMO -->
              <v-col v-else cols="12">
                <v-autocomplete hide-details clearable id="c-selec-data-cliente" item-value="razaoSocial"
                  item-text="razaoSocial" v-model="dataClienteSelecionado" :items="dataClientes"
                  :loading="buscandoDataCliente" outlined dense chips small-chips label="Selecionar Órgão">
                  <template v-slot:item="{ item }">
                    <v-chip :color="item.color" label small class="u-cursor my-2">
                      {{ item.razaoSocial }}
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
              <!-- ###### CAMPOS PARA GERAR EMPRESTIMO ###### -->
              <v-col v-if="!isEmprestimoRecebido" cols="12" class="mt-0 pt-0">
                <v-row v-if="'objectId' in pedidoParaGerarEmprestimo" class="ma-0 pa-0">
                  <!-- Produto Selecionado -->
                  <v-col cols="12" md="6">
                    <v-text-field disabled hide-details dense outlined
                      :value="dataPedidoSelecionado(produtosSelecionados).produto"
                      label="Produto selecionado"></v-text-field>
                  </v-col>
                  <!-- Quantidade para o impréstimo ou resgate -->
                  <!-- Quant. disponível -->
                  <v-col cols="12" md="6">
                    <v-text-field disabled type="number" hide-spin-buttons hide-details dense outlined
                      v-model.number="dataPedidoSelecionado(produtosSelecionados).quantidadeDisponivel"
                      label="Quant. disponível">
                    </v-text-field>
                  </v-col>
                  <!-- Quant. para empréstar -->
                  <v-col v-if="isGerarEmprestimo" cols="12" class="mt-0 pt-0">
                    <v-row justify="center" class="mt-0">
                      <v-col cols="12" md="3" class="mr-0 pr-0 text-right">
                        <v-btn :disabled="desabilitarDiminuirEmprestimo" fab small class="ma-0"
                          @click="quantidadeEmprestada--"><v-icon>remove</v-icon></v-btn>
                      </v-col>
                      <v-col cols="12" md="4" class="mx-3 px-0 text-center">
                        <v-text-field :color="erroParaQuantidadeEmprestada ? 'red' : 'white'" dense type="number"
                          hide-spin-buttons outlined v-model.number="quantidadeEmprestada" label="Quant. para empréstar"
                          :hint="erroParaQuantidadeEmprestada ? 'Entrada. inválida' : ''" persistent-hint>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="3" class="ml-0 pl-0">
                        <v-btn :disabled="desabilitarPlusEmprestimo" fab small class="ma-0"
                          @click="quantidadeEmprestada++"><v-icon>add</v-icon></v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                  <!-- Quant. para resgatar -->
                 <!--  <v-col v-if="isGerarResgate" cols="12" class="mt-0 pt-0">
                    <v-row justify="center" class="mt-0">
                      <v-col cols="12" md="3" class="mr-0 pr-0 text-right">
                        <v-btn fab small class="ma-0"
                          @click="dataPedidoSelecionado(produtosSelecionados).quantidadeRecebida--"><v-icon>remove</v-icon></v-btn>
                      </v-col>
                      <v-col cols="12" md="4" class="mx-3 px-0 text-center">
                        <v-text-field dense outlined v-model.number="quantidadeResgatada" label="Quant. para resgatar"
                          hint="Quant. para emprestar" persistent-hint>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="3" class="ml-0 pl-0">
                        <v-btn fab small class="ma-0"
                          @click="dataPedidoSelecionado(produtosSelecionados).quantidadeRecebida++"><v-icon>add</v-icon></v-btn>
                      </v-col>
                    </v-row>
                  </v-col> -->

                  <!-- Btn gerar empréstimo -->
                  <v-col v-if="isGerarEmprestimo" cols="12" md="6" class="mt-0 pt-0">
                    <v-btn block color="quinta" @click="cancelarGerarEmprestimo()">CANCELAR</v-btn>
                  </v-col>
                  <v-col v-if="isGerarEmprestimo" cols="12" md="6" class="mt-0 pt-0">
                    <v-btn :disabled="erroParaQuantidadeEmprestada" block color="success" @click="gerarEmprestimo()">GERAR
                      EMPRÉSTIMO</v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" v-else>
                <v-alert border="top" dense prominent type="error">
                  Existem empréstimos recebidos deste pedido; você precisa realizar a devolução dos produtos recebidos
                  anteriormente!
                </v-alert>
                <v-col v-if="isGerarEmprestimo" cols="12" md="12" class="mt-0 pt-0">
                  <v-btn block color="quinta" @click="cancelarGerarEmprestimo()">CANCELAR EMPRÉSTIMO</v-btn>
                </v-col>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-scroll-y-transition appear>
        <!-- ######## INICIO LISTA DE EMPRESTIMO #######  -->
        <v-col v-if="produtosSelecionados.length > 0 && emprestimos.length > 0" cols="12" md="12">
          <v-hover class="ma-0 pa-0 mt-4">
            <template #default="{ hover }">
              <v-text-field v-model="consultarEmprestimos" label="Procurar empréstimos"
                placeholder="Consultar empréstimos" color="segunda" background-color="quarta" dense rounded solo flat
                hide-details :class="`elevation-${hover ? 4 : 1}`" clearable>
                <template #append>
                  <v-icon color="segunda">content_paste_search</v-icon>
                </template>
              </v-text-field>
            </template>
          </v-hover>
          <!-- Titulo -->
          <v-col cols="12">
            <h3 class="text-center">Fluxo do Pedido</h3>
          </v-col>
          <!-- Lista de empréstimos -->
          <v-list dense color="transparent">
            <v-list-item v-for="(  itemEmprestimo, indexEmprestimo  ) in emprestimos" :key="indexEmprestimo"
              :class="{ 'u-hide': itemEmprestimo.pesquisa }" class="my-2 pa-3 monoprimeira rounded-lg">
              <v-row :class="{ 'indigo darken-2': itemEmprestimo.emprestimoRecebido }" class="rounded-lg">
                <v-col cols="12" md="9">
                  <v-list-item-content class="pt-0 mt-n1">
                    <v-list-item-title>
                      <div v-if="itemEmprestimo.emprestimoRecebido">
                        <!-- BTN COPIA ID DO EMPRÉSTIMO RECEBIDO-->
                        <p class="pa-0 ma-0">ID DO EMPRÉSTIMO RECEBIDO:
                          <v-btn @click="cop(itemEmprestimo.objectId)" x-small color="indigo accent-3"
                            class="white--text text-overline mb-2">{{ itemEmprestimo.objectId
                            }} <v-icon right color="">content_copy</v-icon> </v-btn>
                        </p>
                        <span>EMPRÉSTIMO RECEBIDO DE:</span><br />
                        <span class="u-texto-wrap">{{ itemEmprestimo.orgaoDoPedidoQEmprestrou }}</span>
                         <!-- BTN COPIA ID DE QUEM EMPRESTOU -->
                         <p class="pa-0 ma-0">ID DE QUEM EMPRESTOU:
                          <v-btn @click="cop(itemEmprestimo.idPedido)" x-small color="indigo accent-3"
                            class="white--text text-overline mb-2">{{ itemEmprestimo.idPedido
                            }} <v-icon right color="">content_copy</v-icon>
                          </v-btn>
                        </p>
                        <v-divider class="my-2" />
                      </div>
                      <div v-else>
                        <!-- BTN COPIA ID DO EMPRÉSTIMO -->
                        <p class="pa-0 ma-0">ID DO EMPRÉSTIMO ENVIADO:
                          <v-btn @click="cop(itemEmprestimo.objectId)" x-small color="monoprimeira2"
                            class="white--text text-overline mb-2">{{ itemEmprestimo.objectId
                            }} <v-icon right color="">content_copy</v-icon> </v-btn>
                        </p>
                        <!-- BTN COPIA ID DE QUEM RECEBEU -->
                        <p class="pa-0 ma-0">ID DE QUEM RECEBEU:
                          <v-btn @click="cop(itemEmprestimo.idDestino)" x-small color="monoprimeira2"
                            class="white--text text-overline mb-2">{{ itemEmprestimo.idDestino
                            }} <v-icon right color="">content_copy</v-icon>
                          </v-btn>
                        </p>
                        <span class="mt-4">EMPRESTADO PARA:</span><br />
                        <span class="u-texto-wrap">{{ itemEmprestimo.orgao }}</span>
                        <v-divider class="my-2" />
                      </div>
                    </v-list-item-title>
                    <v-list-item-subtitle class="my-1">
                      <div v-if="itemEmprestimo.emprestimoRecebido" class="mb-2">
                        <span>
                          QUANT. RECEBIDA: <v-chip small label color="quarta" class="ml-1">{{
                            itemEmprestimo.quantidadeEmprestada }}</v-chip>
                        </span>
                        <span>
                          QUANT. DEVOLVIDA: <v-chip small label color="quarta" class="ml-1">{{
                            calcQuantResgatada(itemEmprestimo) }}</v-chip>
                        </span>

                        <!-- Caso o card de devolução seja clicado a opção abaixo será exibida para devolver a quantidade dos itens do emprestimo recebido isso se tiver mercadoria disponivel para devolução -->
                        <div
                          v-if="isGerarResgate && resgateData.indexEmprestimo === indexEmprestimo && !novoLimiteParaResate">
                          <v-text-field dense type="number" outlined v-model.number="resgateData.quantidadeResgatada"
                            :label="`Quant. Disponível para devolver: ${itemEmprestimo.quantidadeLimite}`"
                            :rules="[value => value > 0 || 'O valor deve ser maior que 0', value => value <= itemEmprestimo.quantidadeLimite || 'Quantidade indisponível para devolução']"
                            @update:error="isHabilitadoParaResgate($event)" class="mt-3 pb-0"
                            :class="{ 'mb-n6': isEnvioParaResgate }">
                            <template v-slot:append-outer>
                              <v-icon v-if="isEnvioParaResgate && resgateData.quantidadeResgatada > 0" color="segunda"
                                @click="criarResgate()">send_and_archive</v-icon>
                              <v-icon v-else disabled color="quarta">send_and_archive</v-icon>
                            </template>
                          </v-text-field>
                        </div>

                        <div
                          v-if="isGerarResgate && resgateData.indexEmprestimo === indexEmprestimo && novoLimiteParaResate">

                          <v-alert dense color="monoprimeira2" type="info" class="u-alert-text">
                            O saldo de quem emprestou é de {{ itemEmprestimo.quantidadeLimite }} Uni. mas este<br />pedido
                            só tem {{ novoLimiteParaResateValor }} prod. disponíveis para resgate.
                          </v-alert>


                          <v-text-field dense type="number" outlined v-model.number="resgateData.quantidadeResgatada"
                            :label="`Quant. Disponível para resgate: ${novoLimiteParaResateValor}`"
                            :rules="[value => value > 0 || 'O valor deve ser maior que 0', value => value <= novoLimiteParaResateValor || 'Quantidade indisponível para resgate']"
                            @update:error="isHabilitadoParaResgate($event)" class="mt-3 pb-0"
                            :class="{ 'mb-n6': isEnvioParaResgate }">
                            <template v-slot:append-outer>
                              <v-icon v-if="isEnvioParaResgate && resgateData.quantidadeResgatada > 0" color="segunda"
                                @click="criarResgate()">send_and_archive</v-icon>
                              <v-icon v-else disabled color="quarta">send_and_archive</v-icon>
                            </template>
                          </v-text-field>

                        </div>

                      </div>
                      <div v-else class="mb-2">
                        <span>
                          QUANT. EMPRESTADA: <v-chip small label color="primeira" class="ml-1">{{
                            itemEmprestimo.quantidadeEmprestada }}</v-chip>
                        </span>
                        <span>
                          QUANT. RESGATADA: <v-chip small label color="primeira" class="ml-1">{{
                            calcQuantResgatada(itemEmprestimo) }}</v-chip>
                        </span>
                        <br />
                        <!-- Caso o card de emprestimo seja clicado a opção abaixo será exibida para resgatar quantidade dos itens do emprestimo -->
                        <v-text-field
                          v-if="isGerarResgate && resgateData.indexEmprestimo === indexEmprestimo && !novoLimiteParaResate"
                          dense type="number" outlined v-model.number="resgateData.quantidadeResgatada"
                          :label="`Quant. Disponível para resgate: ${itemEmprestimo.quantidadeLimite}`"
                          :rules="[value => value > 0 || 'O valor deve ser maior que 0', value => value <= itemEmprestimo.quantidadeLimite || 'Quantidade indisponível para resgate']"
                          @update:error="isHabilitadoParaResgate($event)" class="mt-3 pb-0"
                          :class="{ 'mb-n6': isEnvioParaResgate }">
                          <template v-slot:append-outer>
                            <v-icon v-if="isEnvioParaResgate && resgateData.quantidadeResgatada > 0" color="segunda"
                              @click="criarResgate()">send_and_archive</v-icon>
                            <v-icon v-else disabled color="quarta">send_and_archive</v-icon>
                          </template>
                        </v-text-field>
                        <div
                          v-if="isGerarResgate && resgateData.indexEmprestimo === indexEmprestimo && novoLimiteParaResate">
                          <v-alert dense color="monoprimeira2" type="info" class="u-alert-text">
                            O saldo é de {{ itemEmprestimo.quantidadeLimite }} Uni. mas este cliente só tem {{
                              novoLimiteParaResateValor }} prod.<br /> disponíveis para resgate.
                          </v-alert>


                          <v-text-field dense type="number" outlined v-model.number="resgateData.quantidadeResgatada"
                            :label="`Quant. Disponível para resgate: ${novoLimiteParaResateValor}`"
                            :rules="[value => value > 0 || 'O valor deve ser maior que 0', value => value <= novoLimiteParaResateValor || 'Quantidade indisponível para resgate']"
                            @update:error="isHabilitadoParaResgate($event)" class="mt-3 pb-0"
                            :class="{ 'mb-n6': isEnvioParaResgate }">
                            <template v-slot:append-outer>
                              <v-icon v-if="isEnvioParaResgate && resgateData.quantidadeResgatada > 0" color="segunda"
                                @click="criarResgate()">send_and_archive</v-icon>
                              <v-icon v-else disabled color="quarta">send_and_archive</v-icon>
                            </template>
                          </v-text-field>

                        </div>
                      </div>

                      <span>PRODUTO: <v-chip small label
                          :color="itemEmprestimo.emprestimoRecebido ? 'quarta' : 'primeira'" class="ml-1">{{
                            itemEmprestimo.produto }}</v-chip>
                      </span><br />
                      <v-divider class="my-2" />
                      <div v-if="itemEmprestimo.emprestimoRecebido" class="mt-3">
                        DATA DO RECEBIMENTO REGISTRADO: {{ itemEmprestimo.dataDoEmprestimo | moment('DD/MM/YYYY HH:mm:ss')
                        }}
                      </div>
                      <div v-else class="mt-3">
                        DATA DO EMPRÉSTIMO REGISTRADO: {{ itemEmprestimo.dataDoEmprestimo | moment('DD/MM/YYYY HH:mm:ss')
                        }}
                      </div>
                      USUÁRIO: {{ itemEmprestimo.usuario }}
                      <v-divider class="my-2" />
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="mt-1">
                      <div class="u-texto-wrap">
                        {{ itemEmprestimo.identificacao }}
                      </div>
                      <v-divider class="my-2" />
                      <v-item-group multiple>
                        <!-- TITULO DE DEVOLUÇÕES -->
                        <v-tooltip v-if="itemEmprestimo.emprestimoRecebido" bottom color="red">
                          <template v-slot:activator="{ on, attrs }">
                            <h3 v-bind="attrs" v-on="on" class="mb-2 u-cursor">
                              DEVOLUÇÕES
                              <v-icon small class="mt-n1"> info</v-icon>
                            </h3>
                          </template>
                          <span>Toda mercadoria devolvida é retirada do saldo disponível para empréstimo.</span>
                        </v-tooltip>
                        <!-- TITULO DE RESGATE -->
                        <v-tooltip v-else bottom color="red">
                          <template v-slot:activator="{ on, attrs }">
                            <h3 v-bind="attrs" v-on="on" class="mb-2 u-cursor">
                              RESGATES
                              <v-icon small class="mt-n1"> info</v-icon>
                            </h3>
                          </template>
                          <span>Todo resgate feito é acrescentado no saldo disponível para empréstimo.</span>
                        </v-tooltip>
                        <v-container class="ma-0 pa-0">
                          <v-row dense>
                            <v-col cols="12" md="4" v-for="(  resgate, keyResgate  ) in   itemEmprestimo.resgates  "
                              :key="keyResgate">
                              <v-item v-slot="{ active, toggle }">
                                <v-card :color="active ? 'primary' : ''" @click="toggle">
                                  <v-card-text class="ma-0 pa-2 pb-1">
                                    {{ resgate.quantidadeResgatada }} Unidades <br />
                                  </v-card-text>
                                  <!-- Btn remover resgate -->
                                  <v-btn v-if="isHabilitarEdicao" elevation="10" x-small fab absolute right top color=""
                                    @click="removerResgate(resgate.objectId, keyResgate, indexEmprestimo)">
                                    <v-icon small>mdi-delete</v-icon>
                                  </v-btn>
                                  <v-scroll-y-transition>
                                    <div v-if="active" class="text-caption pa-2 pt-0">
                                      Data: {{ resgate.dataDoResgate.iso | moment('DD/MM/YYYY [Hora:] HH:mm:ss')
                                      }}<br />
                                      Usuário: {{ resgate.usuario }}<br />
                                      ID DO RESGATE: <v-btn @click="cop(resgate.objectId)" x-small color="red darken-1"
                                        class="white--text text-caption mb-2">{{ resgate.objectId
                                        }} <v-icon right color="">content_copy</v-icon> </v-btn><br />
                                      ID DO PAGADOR: <v-btn @click="cop(resgate.idPedidoDevolucao.objectId)" x-small
                                        color="red darken-1" class="white--text text-caption mb-2">{{
                                          resgate.idPedidoDevolucao.objectId
                                        }} <v-icon right color="">content_copy</v-icon> </v-btn><br />
                                      ID DE QUEM EMPRESTOU: <v-btn @click="cop(resgate.idPedido.objectId)" x-small
                                        color="red darken-1" class="white--text text-caption mb-2">{{
                                          resgate.idPedido.objectId
                                        }} <v-icon right color="">content_copy</v-icon> </v-btn><br />
                                    </div>
                                  </v-scroll-y-transition>
                                </v-card>
                              </v-item>
                            </v-col>
                          </v-row>
                        </v-container>

                      </v-item-group>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-col>
                <v-col v-if="itemEmprestimo.quantidadeLimite > 0 || itemEmprestimo.emprestimoRecebido" cols="12" md="3"
                  class="text-center">
                  <v-list-item-action class="pa-2">
                    <div v-if="itemEmprestimo.emprestimoRecebido">

                      <v-btn v-if="!isGerarResgate || resgateData.indexEmprestimo != indexEmprestimo"
                        @click="gerarResgate(itemEmprestimo, indexEmprestimo)" color="quarta">
                        <v-icon color="grey lighten-1" left>mdi-human-dolly</v-icon>
                        DEVOLVER
                      </v-btn>

                      <v-btn v-else elevation="10" small absolute fab plain outlined right @click="cancelarResgate()"
                        color="indigo lighten-3">
                        <v-icon color="grey lighten-1">cancel</v-icon>
                      </v-btn>

                    </div>
                    <div v-else>
                      <!-- Este botão só será exibido se o mesmo não ter sido clicado -->
                      <v-btn v-if="!isGerarResgate || resgateData.indexEmprestimo != indexEmprestimo"
                        @click="gerarResgate(itemEmprestimo, indexEmprestimo)" color="primeira">
                        <v-icon color="grey lighten-1" style="transform: scaleX(-1);" left>mdi-human-dolly</v-icon>
                        RESGATAR
                      </v-btn>
                      <!-- Este botão será exibido se o usuario clicar para resgatar -->
                      <v-btn v-else elevation="10" small absolute fab plain outlined right @click="cancelarResgate()"
                        color="monoprimeira2">
                        <v-icon color="grey lighten-1">cancel</v-icon>
                      </v-btn>
                    </div>
                    <v-btn v-if="isHabilitarEdicao" elevation="10" x-small fab absolute right top color="primary"
                      @click="removerEmprestimo(itemEmprestimo.objectId, indexEmprestimo)" class="">
                      <v-icon small>mdi-delete</v-icon>
                    </v-btn>

                  </v-list-item-action>
                </v-col>
              </v-row>
            </v-list-item>
          </v-list>
        </v-col>
      </v-scroll-y-transition>
    </v-col>
  </v-row>
</template>
  
<script>
import axios from "axios";
import barramento from "@/barramento"
import { urlApi, showError, cofingK } from "@/global";
import addPedidoOuEditar from "./addPedidoOuEditar.vue";
import resumoPedido from "./resumoPedido.vue";
export default {
  name: "TabelaDePedidos",
  components: { addPedidoOuEditar, resumoPedido },
  data: () => ({
    isHabilitarEdicao: false,
    quantidadeDevolocao: 0,
    empresaSelecionada: {},
    dataNutrimix: {
      sold: 5040874,
      key: "80177262-9E41-467F-9E5A-96521191DAB4",
      grupo: "Nutrimix",
      nome: "Nutrimix Comercial Ltda",
    },
    statusUnidadeMedida: true,
    UnidadeMedida: [
      { Litros: "LT" },
      { Unidade: "UN" },
      { Sache: "SA" },
      { Display: "DI" },
      { Kilos: "KG" },
      { Caixa: "CX" },
    ],
    isEditarProduto: false,
    custoLoading: false,
    produtosSelecionados: [],
    selecaoUnica: true,
    search: "",
    dialogAddOuEditProduto: false,
    dialogDelete: false,
    cabecalhoTabela: [
      {
        text: "Empresa",
        align: "start",
        sortable: true,
        value: "empresa",
        width: "5%",
      },
      {
        text: "DADOS DO PEDIDO",
        align: "start",
        sortable: true,
        value: "orgao",
        width: "22%",
      },
      {
        text: "INFO DO Produto",
        align: "start",
        sortable: true,
        value: "produto",
        width: "22%",
      },
      {
        text: "STATUS",
        align: "center",
        sortable: true,
        value: "dataDoPedido",
        width: "15%",
      },
      {
        text: "Recebimento",
        align: "center",
        sortable: true,
        value: "recebimentoMercadoria",
        class: "u-hide",
        cellClass: "u-hide",
      },
      {
        text: "PNE",
        align: "start",
        sortable: true,
        value: "pne",
        class: "u-hide",
        cellClass: "u-hide",
      },
      {
        text: "ID Solution",
        align: "start",
        sortable: true,
        value: "idSolution",
        class: "u-hide",
        cellClass: "u-hide",
      },
      {
        text: "Número Solution ou Plataforma Ni",
        align: "start",
        sortable: true,
        value: "numeroSolutionOuPlataformaNi",
        class: "u-hide",
        cellClass: "u-hide",
      },
      {
        text: "Edital Pregão",
        align: "start",
        sortable: true,
        value: "editalPregao",
        class: "u-hide",
        cellClass: "u-hide",
      },
      {
        text: "Quantidade Pedida",
        align: "end",
        sortable: true,
        value: "quantidadePedida",
        class: "u-hide",
        cellClass: "u-hide",
      },
      {
        text: "Quantidade por Caixa",
        align: "end",
        sortable: true,
        value: "quantidadeCaixa",
        class: "u-hide",
        cellClass: "u-hide",
      },
      {
        text: "Quantidade Total",
        align: "end",
        sortable: true,
        value: "quantidadeTotal",
        class: "u-hide",
        cellClass: "u-hide",
      },
      {
        text: "Valor Individual",
        align: "end",
        sortable: true,
        value: "valorIndividual",
        class: "u-hide",
        cellClass: "u-hide",
      },
      {
        text: "Valor Total",
        align: "end",
        sortable: true,
        value: "valorTotal",
        class: "u-hide",
        cellClass: "u-hide",
      },
      {
        text: "Quantidade Recebida",
        align: "end",
        sortable: true,
        value: "quantidadeRecebida",
        class: "u-hide",
        cellClass: "u-hide",
      },
      {
        text: "Número do Pedido",
        align: "start",
        sortable: true,
        value: "pedidoNumero",
        class: "u-hide",
        cellClass: "u-hide",
      },
      {
        text: "NF",
        align: "start",
        sortable: true,
        value: "nf",
        class: "u-hide",
        cellClass: "u-hide",
      },
      {
        text: "Número Empenho",
        align: "start",
        sortable: true,
        value: "numeroEmpenho",
        class: "u-hide",
        cellClass: "u-hide",
      },
      {
        text: "Criado em",
        align: "start",
        sortable: true,
        value: "createdAt",
        class: "u-hide",
        cellClass: "u-hide",
      },
      {
        text: "Atualizado em",
        align: "start",
        sortable: true,
        value: "updatedAt",
        class: "u-hide",
        cellClass: "u-hide",
      },
      {
        text: "ID",
        align: "start",
        sortable: true,
        value: "objectId",
        class: "u-hide",
        cellClass: "u-hide",

      },
      {
        text: "Editar Produtos",
        align: "right",
        value: "actions",
        sortable: false,
      },
    ],
    produtos: [],
    quantEstoque: 0,
    editedIndex: -1,
    editedItem: {
      empresa: '',
      produto: '',
      orgao: '',
      pne: '',
      idSolution: '',
      numeroSolutionOuPlataformaNi: '',
      numeroEmpenho: '',
      dataDoPedido: '',
      editalPregao: '',
      quantidadeEmpenho: '',
      quantidadePedida: 0,
      quantidadeCaixa: 0,
      quantidadeTotal: 0,
      valorIndividual: 0,
      valorTotal: 0,
      quantidadeRecebida: 0,
      pedidoNumero: '',
      nf: '',
      obs: '',
      dataDoRecebimento: '',
      prazoDeFaturamento: '',
      recebimentoMercadoria: false,
      previsaoDeRecebimento: '',
      inclusaoDePedidoNestle: '',
      pedidoSigecloud: '',
      dataInsights: {
        somaDosEmprestimos: 0,
        totalResgatadoDosEmp: 0,
        somaDasDevolucoes: 0,
      }
    },
    defaultItem: {
      empresa: '',
      orgao: '',
      produto: '',
      pne: '',
      idSolution: '',
      numeroSolutionOuPlataformaNi: '',
      numeroEmpenho: '',
      dataDoPedido: '',
      editalPregao: '',
      quantidadeEmpenho: '',
      quantidadePedida: 0,
      quantidadeCaixa: 0,
      quantidadeTotal: 0,
      valorIndividual: 0,
      valorTotal: 0,
      quantidadeRecebida: 0,
      pedidoNumero: '',
      nf: '',
      obs: '',
      prazoDeFaturamento: '',
      recebimentoMercadoria: false,
      dataDoRecebimento: '',
      previsaoDeRecebimento: '',
      inclusaoDePedidoNestle: '',
      pedidoSigecloud: '',
      dataInsights: {
        somaDosEmprestimos: 0,
        totalResgatadoDosEmp: 0,
        somaDasDevolucoes: 0,
      }
    },
    valorEstoque: 0,
    valorUnidade: 0,
    valorCodigo: 0,
    diasDeEstoqueValor: 0,
    buscandoDataCliente: false,
    dataClienteSelecionado: '',
    dataClientes: [],
    quantidadeEmprestada: 0,
    quantidadeResgatada: 0,
    quantAtualPedidoSelecionado: 0,
    isGerarEmprestimo: false,
    isGerouEmprestimo: false,
    desabilitarPlusEmprestimo: false,
    desabilitarDiminuirEmprestimo: true,
    erroParaQuantidadeEmprestada: false,
    emprestimos: [{
      dataDoEmprestimo: new Date(),
      emprestimoParaOutroPedido: null,
      emprestimoRecebido: null,
      idDestino: {},
      idPedido: "",
      identificacao: "",
      objectId: "",
      orgao: "",
      pesquisa: null,
      produto: "",
      quantidadeEmprestada: 0,
      quantidadeLimite: 0,
      resgates: [],
      somaDosEmprestimos: 0,
      usuario: ""
    }],
    emprestimosSelecionados: 0,
    consultarEmprestimos: '',
    pedidoParaGerarEmprestimo: {},
    isGerarResgate: false,
    isGerarDevolucao: false,
    resgateData: {},
    isEnvioParaResgate: true,
    listaDeProdutos: [],
    isParaOutroPedido: false,
    isEmprestimoRecebido: false,
    novoLimiteParaResate: false,
    novoLimiteParaResateValor: 0,
  }),
  computed: {
    isPar() {
      return (index, isSelected) => {
        if (isSelected) return 'v-data-table__selected'
        if (index % 2 === 0) {
          return 'grey darken-3'
        } else {
          return 'grey darken-4'
        }
      }
    },
    calcQuantResgatada() {
      return emprestimo => {
        const totalResgatado = emprestimo.resgates.reduce((total, resgate) => total + resgate.quantidadeResgatada, 0);
        if (totalResgatado > 0) {
          emprestimo.quantidadeLimite = (emprestimo.quantidadeEmprestada - totalResgatado);
        } else {
          emprestimo.quantidadeLimite = emprestimo.quantidadeEmprestada;
        }
        return totalResgatado
      }
    },
    apenasPedidosDoProdutoSelecionado() {
      return pedidos => this.filtrarPedidosComProdutosSimilares(pedidos, this.produtosSelecionados[0].produto, 5, this.produtosSelecionados[0].objectId)
    },
    dataPedidoSelecionado() {
      return (pedido) => {
        if (pedido.length > 0) {
          return pedido[0]
        } else {
          return [{}]
        }
      }
    },
    formTitle() {
      return this.editedIndex === -1 ? "Novo Pedido" : "Editar Pedido";
    },
  },
  watch: {
    consultarEmprestimos(strNova) {
      if (strNova === "" || strNova == null || strNova == undefined) {
        this.emprestimos.forEach((emprestimo) => {
          emprestimo.pesquisa = false;
        });
        return;
      }
      this.emprestimos.forEach((emprestimo) => {
        let orgao = 'orgaoDoPedidoQEmprestrou' in emprestimo ? emprestimo.orgaoDoPedidoQEmprestrou : emprestimo.orgao;
        if (this.contemString(emprestimo.objectId, strNova) || this.contemString(orgao, strNova)) {
          emprestimo.pesquisa = false;
        } else {
          emprestimo.pesquisa = true;
        }
      });
    },

    quantidadeEmprestada: {
      handler(novo, antigo) {
        this.erroParaQuantidadeEmprestada = false

        if (novo >= this.quantAtualPedidoSelecionado) {
          this.desabilitarPlusEmprestimo = true
        } else {
          if (this.desabilitarPlusEmprestimo) {
            this.desabilitarPlusEmprestimo = false
          }
        }

        if (novo <= 0) {
          this.desabilitarDiminuirEmprestimo = true
        } else {
          if (this.desabilitarDiminuirEmprestimo) {
            this.desabilitarDiminuirEmprestimo = false
          }
        }

        /* click no botão de add */
        if (novo > antigo) {
          this.produtosSelecionados[0].quantidadeDisponivel -= novo - antigo
        }
        /* click no botão de diminuir */
        if (novo < antigo) {
          this.produtosSelecionados[0].quantidadeDisponivel += antigo - novo
        }

        if (novo > this.quantAtualPedidoSelecionado || novo <= 0 || !/^\d+$/.test(novo)) {
          this.quantidadeEmprestada = 0
          this.produtosSelecionados[0].quantidadeDisponivel = this.quantAtualPedidoSelecionado
          this.erroParaQuantidadeEmprestada = true
        }
      }
    },
    dialogAddOuEditProduto(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    this.initialize(false);
    barramento.getOpenEdicao(val => this.isHabilitarEdicao = val)
  },

  methods: {
    filtroParaBuscarPedido(item, queryText) {
        const textOne = item.orgao.toLowerCase()
        const textTwo = item.objectId.toLowerCase()
        const searchText = queryText.toLowerCase()

        return textOne.indexOf(searchText) > -1 ||
          textTwo.indexOf(searchText) > -1
      },
    salvarPedido(produtosParaAlterar) {
      axios
        .post(`${urlApi}/salvar-pedido`, produtosParaAlterar, {
          headers: cofingK,
        })
        .then((res) => {
          this.produtosSelecionados[0] = Object.assign({}, res.data.result)
          const idSalvo = res.data.result.objectId;
          this.initialize(true, idSalvo);
          this.$toasted.global.defaultSuccess({ msg: 'Pedido alterado com sucesso!' });
          this.close();
        })
        .catch(showError);
    },

    editarPedido(pedidoParaAlterar) {
      this.editItem(pedidoParaAlterar)
    },
    isDisponivelParaResgate(id, idEmprestimo) {
      const query = {
        idDestino: id,
      }
      axios
        .post(`${urlApi}/quantidade-disponivel-resgate`, query, { headers: cofingK })
        .then((res) => {
          if (res.data.result < this.emprestimos[idEmprestimo].quantidadeLimite) {
            this.novoLimiteParaResateValor = res.data.result
            this.novoLimiteParaResate = true;
          }
        })
        .catch(showError)
    },
    verificarItemSelecionado() {

      // Essa função verifica se, após o usuário selecionar um pedido para realizar empréstimo, o pedido já foi emprestado para o cliente da tela atual (selecionado para visualizar).

      // Se o pedido já estiver emprestado, a função retorna true, impedindo a realização do empréstimo e exigindo que o usuário selecione outro pedido ou devolva a mercadoria dos empréstimos recebidos.

      // Além disso a função também verifica se o emprestimo recebido foi totalmente devolvido. Se sim, um emprestimo pode ser realizado, caso o contrario, não.

      this.isEmprestimoRecebido = this.emprestimos.some(emprestimo => {
        if (emprestimo.emprestimoRecebido) {
          if (!emprestimo.isTotalDevolvido && emprestimo.idPedido === this.pedidoParaGerarEmprestimo.objectId) {
            return true
          }
        } else {
          return false
        }
      })
    },
    cop(id) {
      navigator.clipboard.writeText(id)
        .then(() => {
          // Feedback de sucesso (opcional)
          this.$toasted.global.defaultSuccess({
            msg: 'Copiado para a área de transferência!'
          });
        })
        .catch(err => {
          // Tratamento de erros (opcional)
          showError('Falha ao copiar:', err);
        });
    },
    cancelarGerarEmprestimo() {
      this.isParaOutroPedido = false
      this.isGerarEmprestimo = false;
      this.dataClientes = [];
      this.dataClienteSelecionado = '';
      this.pedidoParaGerarEmprestimo = {};
      this.isEmprestimoRecebido = false;
      this.produtosSelecionados[0].quantidadeDisponivel = this.quantAtualPedidoSelecionado;
    },
    removerEmprestimo(idEmprestimo, indexEmprestimo) {
      let sendDataEmprestimoRemocao = {
        idEmprestimo: idEmprestimo,
        objectId: this.produtosSelecionados[0].objectId
      }
      axios
        .post(`${urlApi}/remover-emprestimo`, sendDataEmprestimoRemocao, {
          headers: cofingK,
        })
        .then((res) => {
          this.emprestimos.splice(indexEmprestimo, 1);
          sendDataEmprestimoRemocao = {};
          this.resgateData = {};
          this.isGerarResgate = false;
          this.produtosSelecionados[0].quantidadeDisponivel = res.data.result.quantidadeDisponivel;
          this.$toasted.global.defaultSuccess();
        })
        .catch((err) => {
          sendDataEmprestimoRemocao = {};
          this.resgateData = {};
          this.isGerarResgate = false;
          showError(err.response.data.error);
        });
    },
    removerPedido(id) {
      let remocaoPedido = {
        objectId: id
      }
      axios
        .post(`${urlApi}/remover-pedido`, remocaoPedido, {
          headers: cofingK,
        })
        .then(() => {
          this.emprestimos = [];
          this.resgateData = {};
          this.isGerarResgate = false;
          this.isHabilitarEdicao = false;
          this.editedIndex = -1;
          this.editedItem = Object.assign({}, this.defaultItem);
          this.produtosSelecionados = [];
          this.initialize(false);
          barramento.enviarEdicao(false);
          this.$toasted.global.defaultSuccess();
        })
        .catch(() => {
          /* sendDataResgateRemocao = {} */
          this.resgateData = {}
          this.isGerarResgate = false
        });
    },
    removerResgate(gesgate, keyResgate, indexEmprestimo) {
      let sendDataResgateRemocao = {
        idResgate: gesgate
      }
      axios
        .post(`${urlApi}/remover-resgate`, sendDataResgateRemocao, {
          headers: cofingK,
        })
        .then(() => {
          sendDataResgateRemocao = {}
          this.resgateData = {}
          this.isGerarResgate = false
          this.emprestimos[indexEmprestimo].resgates.splice(keyResgate, 1);
          this.listarEmprestimos(this.produtosSelecionados[0].objectId);
          this.$toasted.global.defaultSuccess();
        })
        .catch(() => {
          sendDataResgateRemocao = {}
          this.resgateData = {}
          this.isGerarResgate = false
        });
    },
    async listarEmprestimos(id) {
      const promises = [
        this.listarEmprestimosEnviados(id),
        this.listarEmprestimosRecebidos(id),
      ];

      return Promise.all(promises)
        .then((results) => {
          this.emprestimos = results.flat();
        })
        .catch((err) => {
          throw err;
        });
    },
    gerarDevolucao(itemEmprestimo, indexEmprestimo) {
      this.resgateData = {};
      this.resgateData = Object.assign({}, itemEmprestimo);
      this.resgateData.quantidadeParaDevolver = 0;
      this.resgateData.indexEmprestimo = indexEmprestimo;
      this.isGerarDevolucao = true;
    },
    isHabilitadoParaResgate(validado) {
      if (!validado) {
        this.isEnvioParaResgate = true
      } else {
        this.isEnvioParaResgate = false
      }
    },
    /* Executa assim que o botão de RESGATAR emprestimo for clicado */
    gerarResgate(itemEmprestimo, indexEmprestimo) {
      this.resgateData = {};
      this.resgateData = Object.assign({}, itemEmprestimo);
      this.resgateData.quantidadeResgatada = 0;
      this.resgateData.indexEmprestimo = indexEmprestimo;
      this.isGerarResgate = true;
      this.cancelarGerarEmprestimo()
      this.isDisponivelParaResgate(itemEmprestimo.idDestino, indexEmprestimo)

    },
    /* Executa assim que o usuario define um valor valido a ser resgatado no input, o user envia o id do pedido e também o id do emprestimo, mas a quantidade a ser resgatada. */
    async criarResgate() {
      let sendDataResgate = {
        produto: this.resgateData.produto,
        orgao: this.resgateData.orgao,
        dataDoResgate: await this.getDataEHoraExatas(),
        quantidadeResgatada: this.resgateData.quantidadeResgatada,
        idEmprestimo: this.resgateData.objectId,
        idPedido: this.resgateData.idPedido,
        idPedidoDevolucao: this.resgateData.idDestino
      }
      axios
        .post(`${urlApi}/criar-resgate`, sendDataResgate, {
          headers: cofingK,
        })
        .then(() => {
          sendDataResgate = {}
          this.resgateData = {}
          this.isGerarResgate = false
          this.listarEmprestimos(this.produtosSelecionados[0].objectId);
          this.$toasted.global.defaultSuccess();
        })
        .catch(() => {
          sendDataResgate = {}
          this.resgateData = {}
          this.isGerarResgate = false
        });

    },
    /* Executa assim que o botão de GERAR EMPRESTIMO for clicado */
    async gerarEmprestimo() {
      let dataEmprestimo = {
        produto: this.produtosSelecionados[0].produto,

        orgao: Object.keys(this.pedidoParaGerarEmprestimo).length === 0 ? this.dataClienteSelecionado : this.pedidoParaGerarEmprestimo.orgao,

        quantidadeEmprestada: this.quantidadeEmprestada,
        quantidadeDisponivel: this.produtosSelecionados[0].quantidadeDisponivel,
        dataDoEmprestimo: await this.getDataEHoraExatas(),
        emprestimoParaOutroPedido: true,
        idPedido: this.produtosSelecionados[0].objectId,
        idDestino: Object.keys(this.pedidoParaGerarEmprestimo).length === 0 ? null : this.pedidoParaGerarEmprestimo.objectId,
      }
      axios
        .post(`${urlApi}/criar-emprestimo`, dataEmprestimo, {
          headers: cofingK,
        })
        .then(() => {
          this.isGerouEmprestimo = true;
          this.quantidadeEmprestada = 0;
          this.dataClienteSelecionado = '';
          dataEmprestimo = {};
          this.novoLimiteParaResateValor = 0
          this.novoLimiteParaResate = false;
          this.listarEmprestimos(this.produtosSelecionados[0].objectId);
          this.cancelarGerarEmprestimo()
          this.$toasted.global.defaultSuccess();
        })
        .catch(() => {
          this.isGerouEmprestimo = true;
          this.listarEmprestimosEnviados(this.produtosSelecionados[0].objectId);
          this.quantidadeEmprestada = 0;
          this.dataClienteSelecionado = '';
          dataEmprestimo = {};
          this.novoLimiteParaResateValor = 0
          this.novoLimiteParaResate = false;
          return '';
        });
    },
    cancelarResgate() {
      this.resgateData = {};
      this.isGerarResgate = false;
      this.novoLimiteParaResateValor = 0
      this.novoLimiteParaResate = false;
    },
    validateQuantidadeEmprestada(event) {
      const regex = /^[0-9]$/;
      const isValid = regex.test(event.key);
      if (!isValid) {
        this.erroParaQuantidadeEmprestada = true
      }
    },
    identificarSelecao(linha) {
      if (linha.value) {
        this.quantAtualPedidoSelecionado = linha.item.quantidadeRecebida;
        this.listarEmprestimos(linha.item.objectId)
        /* this.produtosSelecionados[0].quantidadeDisponivel = linha.item.quantidadeRecebida; */
        linha.item.naoSelecionado = false
      } else {
        this.quantAtualPedidoSelecionado = 0;
        this.isGerarEmprestimo = false;
        this.quantidadeEmprestada = 0;
        linha.item.naoSelecionado = true;
        this.emprestimos = [];
        this.pedidoParaGerarEmprestimo = {};
        this.produtosSelecionados[0] = {};
        this.cancelarResgate();
        this.cancelarGerarEmprestimo();
      }
    },
    fazerEmprestimo(isParaOutroPedido) {
      this.isParaOutroPedido = isParaOutroPedido
      this.isGerarEmprestimo = true;
      if (this.isParaOutroPedido) {
        this.dataClientes = [];
        this.dataClienteSelecionado = '';
      } else {
        this.pedidoParaGerarEmprestimo = {};
        this.getDataProdutos();
      }
    },
    getDataProdutos() {
      this.buscandoDataCliente = true;
      axios
        .post(`${urlApi}/listar-clientes`, this.user, { headers: cofingK })
        .then((res) => {
          this.dataClientes = res.data.result;
          this.buscandoDataCliente = false;
        })
        .catch(erro => {
          this.buscandoDataCliente = false;
          showError(`Erro ao buscar pruduto | ${erro}`)
        });
    },
    async getDataEHoraExatas() {
      try {
        const { data: { datetime } } = await axios.get('http://worldtimeapi.org/api/ip');
        const dataHoraCorretas = new Date(datetime);
        return dataHoraCorretas;
      } catch (e) {
        showError(`Erro ao obter a data e hora corretas${e}`)
      }
    },

    deletarProduto(idProduto) {
      let deleteProduto = {};
      deleteProduto.objectId = idProduto;
      axios
        .post(`${urlApi}/deletar-produto`, deleteProduto, {
          headers: cofingK,
        })
        .then(() => {
          this.closeDelete();
          this.$toasted.global.defaultSuccess({
            msg: "Produto excluido com sucesso!",
          });
        })
        .catch(showError);
    },
    editItem(item) {
      this.editedIndex = this.produtos.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.cancelarResgate();
      this.cancelarGerarEmprestimo();
      this.dialogAddOuEditProduto = true;
    },

    deleteItem(item) {
      this.editedIndex = this.produtos.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.produtos.splice(this.editedIndex, 1);
      this.deletarProduto(this.editedItem.objectId);
    },

    close() {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.dialogAddOuEditProduto = false;
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save(dadosItem) {
      this.salvarPedido(dadosItem);
    },


    calcularDistanciaLevenshtein(str1, str2) {
      // Remover espaços
      str1 = str1.replace(/\s/g, '');
      str2 = str2.replace(/\s/g, '');

      const len1 = str1.length + 1;
      const len2 = str2.length + 1;

      // Inicializar a matriz de distância
      const matriz = Array.from({ length: len1 }, () => Array(len2).fill(0));

      // Inicializar a primeira coluna
      for (let i = 0; i < len1; i++) {
        matriz[i][0] = i;
      }

      // Inicializar a primeira linha
      for (let j = 0; j < len2; j++) {
        matriz[0][j] = j;
      }

      // Preencher a matriz de distância
      for (let i = 1; i < len1; i++) {
        for (let j = 1; j < len2; j++) {
          const custoSubstituicao = str1[i - 1] === str2[j - 1] ? 0 : 1;
          matriz[i][j] = Math.min(
            matriz[i - 1][j] + 1,         // Deleção
            matriz[i][j - 1] + 1,         // Inserção
            matriz[i - 1][j - 1] + custoSubstituicao // Substituição
          );
        }
      }

      return matriz[len1 - 1][len2 - 1];
    },

    saoNomesSimilares(nome1, nome2, limite) {
      const distancia = this.calcularDistanciaLevenshtein(nome1.toLowerCase(), nome2.toLowerCase());
      return distancia <= limite;
    },

    filtrarPedidosComProdutosSimilares(arrayDePedidos, nomeProduto, limite, objectId) {
      return arrayDePedidos.filter(pedido => {
        if (pedido.objectId === objectId) {
          return false;
        }
        return this.saoNomesSimilares(pedido.produto, nomeProduto, limite);
      });
    },
    contemString(texto, busca) {
      const textoFormatado = texto
        .toLowerCase()
        .normalize("NFD")
        .replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, "");

      const buscaFormatada = busca
        .toLowerCase()
        .normalize("NFD")
        .replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, "");

      return textoFormatado.includes(buscaFormatada);
    },

    // Obtém os empréstimos enviados
    listarEmprestimosEnviados(id) {
      const dataQuery = {
        idPedido: id,
      };
      return axios.post(`${urlApi}/listar-emprestimos-id`, dataQuery, { headers: cofingK })
        .then((res) => {
          this.produtosSelecionados[0].quantidadeDisponivel = res.data.result.quantidadeDisponivel;
          this.quantAtualPedidoSelecionado = res.data.result.quantidadeDisponivel;
          this.produtosSelecionados[0].dataInsights = Object.assign({}, res.data.result.dataInsights);
          return res.data.result.emprestimosEnviados;
        })
        .catch((err) => {
          showError('Erro ao listar empréstimos enviados' `${err}`)
          throw err;
        });
    },

    // Obtém os empréstimos recebidos
    listarEmprestimosRecebidos(id) {
      const dataQuery = {
        idDestino: id,
      };
      return axios.post(`${urlApi}/listar-emprestimos-recebidos-id`, dataQuery, { headers: cofingK })
        .then((res) => {
          return res.data.result.emprestimosRecebidos;
        })
        .catch((err) => {
          showError(` Erro ao listar empréstimos recebidos, ${err}`)
          throw err;
        });
    },
    initialize(isAtualizacao, id) {
      this.custoLoading = true;
      axios
        .post(`${urlApi}/listar-pedidos`, this.user, { headers: cofingK })
        .then((res) => {

          res.data.result.forEach(el => {
            el.naoSelecionado = true;
            el.dataInsights = {
              somaDosEmprestimos: 0,
              totalResgatadoDosEmp: 0,
              somaDasDevolucoes: 0,
            };
            if (isAtualizacao && el.objectId === id) {
              this.produtosSelecionados[0] = el;
              el.naoSelecionado = false;
            }
          })
          this.produtos = res.data.result;
          if (isAtualizacao) {
            this.listarEmprestimos(id);
          }
          this.custoLoading = false;
        })
        .catch(showError);
    },
  },
};
</script>
  
<style>
.v-data-table__selected {
  background: #3331ab !important;
  transition: background 0.2s ease;
}

.c-system-2-row {
  min-height: 100%;
  height: calc(100vh - 66px);
  position: static;
  width: 100%;
}

.c-system-2-coluna,
.c-system-1-coluna {
  min-height: 100%;
  height: 100%;
  overflow-y: auto;
  top: 0;
}

.u-alert-text {
  font-size: 9px !Important;
  line-height: 11px;
  text-transform: uppercase;
  height: 34px;
  width: 338px;
  color: #ffffff !important;
  font-weight: 900 !important;
  letter-spacing: 0px !Important;
  padding: 0px;
  margin: 0px;
  padding-top: 5px;
  padding-left: 10px;
  margin-top: 5px;
}

.u-text-id {
  font-size: 9px !important;
}

.u-texto-wrap {
  white-space: normal !important;
}

.v-list-item--highlighted {
  background: #5e1817;
}

.u-font-x-samll {
  font-size: 9px;
}

#c-selec-data-cliente .v-list {
  background: #3c3c3c !important;
}

#c-tabela-pedidos tbody tr {
  height: 120px;
}

#c-tabela-pedidos .v-input--selection-controls__ripple {
  height: 16px;
  width: 16px;
  left: -8px;
  top: calc(50% - 15px);
}

#c-tabela-pedidos .v-icon.v-icon {
  font-size: 17px;
}

#c-tabela-pedidos .v-input--selection-controls__input {
  margin-right: -33px;
}

#c-tabela-pedidos .v-input--selection-controls__input {
  height: 14px;
  width: 14px;
  margin-left: -2px;
}

#c-tabela-pedidos tbody td {
  font-size: 11px;
}

#c-tabela-pedidos .v-data-table-header span {
  font-size: 10px;
  text-transform: uppercase;
}

#c-tabela-pedidos thead th {
  height: 30px !important;
  line-height: 8px;
  text-align: center;
  background: #353535;
  margin: 0px !Important;

}

#c-tabela-pedidos thead th {
  background: #202020;
}

/* #c-tabela-pedidos {
  width: 50%;
} */
.custom-scrollbar-container {
  max-height: 300px;
  /* Defina a altura máxima desejada para a tabela */
  overflow-y: auto;
  /* Adicione a rolagem vertical */
}

.custom-scrollbar-container::-webkit-scrollbar {
  width: 8px;
  /* Largura da barra de rolagem */
}

.custom-scrollbar-container::-webkit-scrollbar-thumb {
  background-color: #555555;
  /* Cor da barra de rolagem */
  border-radius: 8px;
  /* Raio da borda da barra de rolagem */
  border: 2px solid #f1f1f1;
  /* Cor da borda da barra de rolagem */
}

.custom-scrollbar-container::-webkit-scrollbar-track {
  background-color: transparent;
  /* Cor do fundo da barra de rolagem */
}

#c-tabela-pedidos .v-data-table__wrapper {
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #555555 #f1f1f1;
}

#c-tabela-pedidos .v-data-table__wrapper::-webkit-scrollbar {
  width: 5px;
  /* Largura da barra de rolagem */
}

#c-tabela-pedidos .v-data-table__wrapper::-webkit-scrollbar-thumb {
  background-color: #727272;
  /* Cor da barra de rolagem */
  border-radius: 8px;
  /* Raio da borda da barra de rolagem */
  border: 2px solid transparent;
  /* Cor da borda da barra de rolagem */
}

#c-tabela-pedidos .v-data-table__wrapper::-webkit-scrollbar-corner {
  background-color: rgb(95, 184, 12);
}

#c-tabela-pedidos .v-data-table__wrapper::-webkit-scrollbar-track {
  background-color: transparent;
  /* Cor do fundo da barra de rolagem */
}

#c-tabela-pedidos .v-data-table__wrapper::-webkit-resizer {
  background-color: #555555;
}

.c-select-un-global .v-messages__message {
  line-height: 14px;
  hyphens: none;
}

.c-tabela-pedidos tbody tr>td:first-of-type {
  width: 4%;
}

.c-tab-produto__col__input input::-webkit-outer-spin-button,
.c-tab-produto__col__input input::-webkit-inner-spin-button {
  display: none;
  margin: 0px;
}

.c-tab-produto__col__input input[type="number"] {
  -moz-appearance: textfield;
}

.c-tab-produto__periodoRef input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  margin: 0px;
  padding: 0px;
}

.c-tabela-pedidos {
  position: relative;
}

.c-select-empresa .v-select-list {
  background: rgb(88 88 88 / 91%) !important;
}

#c-select-un__global {
  position: relative;
}

#c-select-un__global .v-menu__content {
  left: 0px !important;
  min-width: 100% !important;
}

#c-select-un__global .v-select-list {
  background: #ab3331 !important;
  padding: 0px !important;
}
</style>
  