import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import pt from 'vuetify/lib/locale/pt';

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: { pt },
        current: 'pt',
      },
      theme: {
        themes: {
          light: {
            primary: '#CA4745',
            secondary: '#ab3331',
            primeira: '#ab3331',
            segunda: '#C62828',
            terceira: '#D32F2F',
            quarta: '#3331AB',
            quinta: '#585858',
            quintaMono: '#727272',
            sexta: '#e1e1e1',
            monosexta: '#C8C8C8',
            oitava: '#a0a0a0',
            nona: 'e6c2c2',
            decima: '0baf2e',
            destaque: '#4caf50',
            destaquePlus: '#02ff00',
            dprimeira: '#3c3c3c',
            monoprimeira: '#565656',
            monoprimeira2: '#707070',
            accent: '#8c9eff',
            error: '#ff3131',
            anchor: '#131111'
          },
        },
      },
});
