<template>
  <div>
    <!-- <v-alert
      class="mb-10"
      border="right"
      colored-border
      type="error"
      elevation="2"
      dismissible
    >
      Sua sessão expirou!
      Por motivos de segurança sua sessão possui um prazo de três dias, para renovar seu prazo basta entrar com suas credenciais novamente.
    </v-alert> -->
    <v-slide-y-transition appear>
      <v-row justify="center" align="center" align-content="center" class="mb-3">
        <v-col cols="12">
          <v-img position="center center" src="@/assets/logo-nutrimix-distribuidora.svg" contain height="70"></v-img>
              <p class="ma-0 mt-1 c-nome-sistema decima--text text-center"><span class="">Controle de Pedidos </span><br /><span
                  class="u-absolute u-text-versao">v.1.0</span>
              </p>
        </v-col>
      </v-row>
    </v-slide-y-transition>
    <v-slide-y-reverse-transition appear>
      <v-card class="mx-auto" max-width="344" flat elevation="20" shaped color="grey darken-3">
        <v-row class="d-flex flex-column pa-5">
          <v-col>
            <v-text-field color="decima" v-model="user.email" type="email" name="email" append-icon="email"
              :rules="[rules.required, rules.email]" label="E-mail">
            </v-text-field>
          </v-col>

          <v-col>
            <v-text-field color="decima" :append-icon="trocarIconeLogin ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required, rules.min]" :type="trocarIconeLogin ? 'text' : 'password'" name="password"
              label="Senha" class="input-group--focused" @click:append="trocarIconeLogin = !trocarIconeLogin"
              v-on:keyup.enter="signin" v-model="user.password"></v-text-field>
          </v-col>

          <v-col class="d-flex justify-end mt-n5">
            <v-btn color="#943634" class="white--text" @click="signin" v-on:dblclick.prevent>ENTRAR</v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-slide-y-reverse-transition>
  </div>
</template>

<script>
import axios from "axios";
import { urlApi, showError, cofingK, userKey, apiMagically } from "@/global";
import barramento from "@/barramento";
export default {
  name: "CardDeLogin",
  props: {
    sessaoExpirada: Boolean
  },
  data: function () {
    return {
      user: {},
      trocarIconeLogin: false,
      rules: {
        required: value => !!value || "Campo obrigatório.",
        min: v => {
          if (v == undefined) {
            return "Digite uma senha";
          } else {
            return v.length >= 8 || "Min de 8 caracteres";
          }
        },
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Email inválido.";
        }
      }
    };
  },

  methods: {
    getUrlApiMagical() {
      axios
        .post(`${urlApi}/get-dados-sistema`, {})
        .then((res) => {
          apiMagically.urlsApi = res.data.result[0].urlsApi
        })
        .catch(showError);
    },
    signin() {
      axios
        .post(`${urlApi}/login`, this.user, { headers: cofingK })
        .then(res => {
          localStorage.setItem(userKey, JSON.stringify(res.data.result));
          axios.defaults.headers.post["X-Parse-Session-Token"] = `${res.data.result.sessionToken}`;
          this.getUrlApiMagical()
          this.$toasted.global.defaultSuccess();
          barramento.enviarStatusLogin(true, false);
        })
        .catch(showError);
    }
  }
};
</script>
<style>
.u-text-versao{
  left: 0;
    right: 0;
    margin-left: calc(328px);
    margin-top: -8px;
    font-size: 11px!important;
}
.c-nome-sistema {
    display: block;
    text-align: center;
    padding-left: 203px!important;
}
</style>